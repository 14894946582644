import firebase from "firebase";
import "firebase/auth";

// if (typeof window !== "undefined" && !firebase.apps.length) {
//     firebase.initializeApp({
//         apiKey: "AIzaSyC-Jna5XTa18AO8iGSK-zUZ-6SexWc3ZLU",
//         authDomain: "nec-bi-dev.firebaseapp.com",
//         databaseURL: "https://nec-bi-dev.firebaseio.com",
//         projectId: "nec-bi-dev",
//         storageBucket: "nec-bi-dev.appspot.com",
//         messagingSenderId: "678634054043",
//         appId: "1:678634054043:web:b654de9fbed3c5860a413e",
//     });

//     firebase.auth().languageCode = "vi";
// }

// if (typeof window !== "undefined" && !firebase.apps.length) {
//   firebase.initializeApp({
//     apiKey : "AIzaSyDhTn-p8fdmuyNuvafjN1nx9TxBmsw90cM" , 
//     authDomain : "wifi-marketing-chiyoda.firebaseapp.com" , 
//     projectId : "wifi-marketing-chiyoda" , 
//     storageBucket : "wifi-marketing-chiyoda.appspot.com" , 
//     messagingSenderId : "1025289488893" , 
//     appId : "1:1025289488893:web:7ecd918f8f21f736807194" , 
//     measurementId : "G-KTT104C7JW"
//   });
//   firebase.auth().languageCode = "vi";
// }

// if (typeof window !== "undefined" && !firebase.apps.length) {
//   firebase.initializeApp({
//     apiKey: "AIzaSyBMs4IfFnElM2Ja8zWfbSRVqbyqb0KBPns",
//     authDomain: "chateraise-wifi-marketing-test.firebaseapp.com",
//     projectId: "chateraise-wifi-marketing-test",
//     storageBucket: "chateraise-wifi-marketing-test.appspot.com",
//     messagingSenderId: "525897567314",
//     appId: "1:525897567314:web:4853ac11e5d331e5f6238b",
//     measurementId: "G-F6YBJ419G8"
//   });
//   firebase.auth().languageCode = "vi";
// }

if (typeof window !== "undefined" && !firebase.apps.length) {
  firebase.initializeApp({
    apiKey: "AIzaSyAY_OKWqsdLPgDALz-uwfQ95JjBIj0YUyA",
    authDomain: "zensho-membership-test.firebaseapp.com",
    projectId: "zensho-membership-test",
    storageBucket: "zensho-membership-test.appspot.com",
    messagingSenderId: "309150824156",
    appId: "1:309150824156:web:0b84dbc5845d370143a619",
    measurementId: "G-5NNDZKVCB7"
  });
  firebase.auth().languageCode = "vi";
}

export { firebase };
